<template>
  <component
    :is="wrapperComponent"
    :to="props.link?.href"
    class="group relative flex flex-1 cursor-pointer flex-nowrap bg-blue-600 transition-colors hover:bg-blue-50 motion-reduce:transition-none"
  >
    <div
      class="relative overflow-hidden bg-grey"
      data-el="ui-simple-box-image-wrapper"
    >
      <UiImage
        v-if="props.image?.url"
        :src="props.image?.url"
        loading="lazy"
        :width="props.image?.width"
        :height="props.image?.height"
        class="absolute size-full object-cover transition-transform group-hover:scale-110 motion-reduce:transition-none"
      />
      <span
        v-if="props.type"
        class="absolute -left-2 -top-1 inline-flex h-8 items-center rounded-br-2xl border-4 border-solid border-white px-2.5 py-2 text-xs font-bold text-white"
        :data-theme="props.type"
      >
        {{ props.promotionLabel }}
      </span>
    </div>
    <div class="flex w-full flex-col overflow-hidden py-4 pl-5 pr-7 lg:pl-6 lg:pr-5">
      <div class="mb-5 flex items-center gap-2.5 lg:mb-3">
        <UiIcon
          v-if="props.iconTheme"
          name="services-icon"
          width="36px"
          height="20px"
          :class="rawlplugServicesIcon"
        />
        <h3
          v-if="props.header"
          class="mb-0 line-clamp-2 text-ellipsis text-base font-bold leading-6 text-white lg:text-lg lg:leading-6"
        >
          {{ props.header }}
        </h3>
      </div>
      <p
        v-if="props.description"
        class="line-clamp-3 text-sm text-white lg:max-h-20 lg:text-base"
        :style="{ maxWidth: '209px' }"
      >
        {{ props.description }}
      </p>
    </div>
  </component>
</template>

<script lang="ts" setup>
import UiImage from '../UiImage/UiImage.vue'
import UiIcon from '../UiIcon/UiIcon.vue'
import type { SimpleBox } from './UiSimpleBox.types'

const props = defineProps<SimpleBox>()

const wrapperComponent = computed(() =>
  props.link?.href ? resolveComponent('nuxt-link') : 'div',
)

const rawlplugServicesIcon = computed(() => {
  switch (props.iconTheme) {
    case 'services':
      return 'text-orange'
    case 'software':
      return 'text-blue-250'
    default:
      return 'text-green'
  }
})
</script>

<style lang="postcss">
[data-el='ui-simple-box-image-wrapper'] {
  width: 140px;
  min-width: 140px;
  min-height: 140px;

  @media screen(lg) {
    width: clamp(50px, 10vw, 140px);
    min-width: clamp(50px, 10vw, 140px);
  }
}

[data-theme='green'] {
  background-image: linear-gradient(180deg, #21a54f 0%, #019c7d 100%);
}

[data-theme='orange'] {
  background-image: linear-gradient(180deg, #fbba06 0%, #ea6a1b 100%);
}

[data-theme='purple'] {
  background-image: linear-gradient(75.76deg, #32006b 1.53%, #3f0d8f 89.55%);
}
</style>
